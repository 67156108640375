<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- SIMULATION VIEW -->
    <b-row v-if="$store.state.designUI.btn_header.show" class="align-items-center">
      <b-button id="btn-filter" variant="pink" @click="clickBtnHeader" class="m-1" style="background-color: #0032a0; color: white;">
        <b-icon-sliders/> Global Filter
      </b-button>
      <b-popover target="btn-filter" placement="bottomright" triggers="hover">Filtro global para toda la página</b-popover>

      <div v-if="$store.state.designUI.btn_header.data.bpu && $store.state.designUI.btn_header.data.bpu.length > 0" class="mr-1">
        <strong>BPU: </strong> {{ $store.state.designUI.btn_header.data.bpu[0] }}
      </div>
      
      <div v-if="$store.state.designUI.btn_header.data.brand_category && $store.state.designUI.btn_header.data.brand_category.length > 0" class="mr-1">
        <strong>BRAND CATEGORY: </strong> {{ $store.state.designUI.btn_header.data.brand_category[0] }}
      </div>
      
      <div v-if="$store.state.designUI.btn_header.data.application_form && $store.state.designUI.btn_header.data.application_form.length > 0" class="mr-1">
        <strong>APPLICATION FORM: </strong> {{ $store.state.designUI.btn_header.data.application_form[0] }}
      </div>
      
      <div v-if="$store.state.designUI.btn_header.data.canal && $store.state.designUI.btn_header.data.canal.length > 0" class="mr-1">
        <strong>CANAL: </strong> {{ $store.state.designUI.btn_header.data.canal[0] }}
      </div>
      
      <div v-if="$store.state.designUI.btn_header.data.clasificacion && $store.state.designUI.btn_header.data.clasificacion.length > 0" class="mr-1">
        <strong>TYPE: </strong> {{ $store.state.designUI.btn_header.data.clasificacion[0] }}
      </div>
      
      <div v-if="$store.state.designUI.btn_header.data.year && $store.state.designUI.btn_header.data.year.length > 0" class="mr-1">
        <strong>YEAR: </strong> {{ $store.state.designUI.btn_header.data.year[0] }}
      </div>
      
      <div v-if="$store.state.designUI.btn_header.data.month && $store.state.designUI.btn_header.data.month.length > 0" class="mr-1">
        <strong>MONTH: </strong> {{ $store.state.designUI.btn_header.data.month[0] }}
      </div>
    </b-row>

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <!-- Left Col -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div> -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.fullName || userData.username }}
            </p>
            <span class="user-status">{{ userData.role }}</span>
          </div>
          <b-avatar
            size="40"
            :src="userData.avatar"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          >
            <feather-icon
              v-if="!userData.fullName"
              icon="UserIcon"
              size="22"
            />
          </b-avatar>
        </template>

        <b-dropdown-item
          :to="{ name: 'account-setting'}"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Setting</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item> -->

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item> -->

        <!-- <b-dropdown-item
          :to="{ name: 'misc-coming-soon' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item 
          link-class="d-flex align-items-center"
          @click="logoutCurrentUser"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
  BRow,  BButton, BPopover, BIconSliders
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { useLogout } from '@/auth/utils'
import { avatarText } from '@core/utils/filter'
import store from '@/store'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BRow,
    BButton,

    // Navbar Components
    DarkToggler,
    BPopover,
    BIconSliders
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  methods: {
    clickBtnHeader() {
      store.commit('designUI/BTN_HEADER_SHOW_FILTER', true)
    }
  },
  setup() {

    const {
      logoutCurrentUser
    } = useLogout()

    return {
      logoutCurrentUser,
    }
  }
}
</script>
